import { useQuery } from '@tanstack/react-query';
import { postalCodeKeys } from '.';
import { fetchCountries, fetchPostalCodeLocations, FetchPostalCodeLocationsParams } from './connector';

const useCountries = (enabled = true) =>
  useQuery({
    enabled,
    queryFn: () => fetchCountries(),
    queryKey: postalCodeKeys.countries(),
    select: (data) => data?.countries || [],
  });

const usePostalCodeLocations = ({
  borderZoneOnly = false,
  countryIsoCode,
  enabled = true,
  searchTerm,
}: FetchPostalCodeLocationsParams & { enabled?: boolean }) =>
  useQuery({
    enabled,
    queryFn: () => fetchPostalCodeLocations({ borderZoneOnly, countryIsoCode, searchTerm }),
    queryKey: postalCodeKeys.postalCodeLocations({ borderZoneOnly, countryIsoCode, searchTerm }),
    select: (data) => data?.postalCodes || [],
  });

export { useCountries, usePostalCodeLocations };
