import * as React from 'react';
import { tv } from 'tailwind-variants';
import { cn } from 'utils/cn';
import { Icon, IconProps } from '../Icon/Icon';
import { TextLink } from '../Link/Link';
import { textVariants } from '../Text/Text';

const buttonVariants = tv({
  base: `${textVariants.body} border text-foreground font-medium gap-2 inline-flex items-center justify-center whitespace-nowrap border-offset-background transition-colors px-6 py-2.5 rounded-full focus-visible:outline-none disabled:opacity-65 disabled:pointer-events-none`,
  compoundVariants: [
    {
      className:
        'text-button-primary-default-background border-button-primary-default-background hover:bg-button-primary-hover-background hover:text-button-primary-hover-foreground hover:border-button-primary-hover-border',
      outlined: true,
      variant: 'primary',
    },
    {
      className:
        'bg-button-primary-default-background text-button-primary-default-foreground border-button-primary-default-border hover:bg-button-primary-hover-background hover:text-button-primary-hover-foreground hover:border-button-primary-hover-border',
      outlined: false,
      variant: 'primary',
    },
    {
      className:
        'text-button-secondary-default-background border-button-secondary-default-background hover:bg-button-secondary-hover-background hover:text-button-secondary-hover-foreground hover:border-button-secondary-hover-border',
      outlined: true,
      variant: 'secondary',
    },
    {
      className:
        'bg-button-secondary-default-background text-button-secondary-default-foreground border-button-secondary-default-border hover:bg-button-secondary-hover-background hover:text-button-secondary-hover-foreground hover:border-button-secondary-hover-border',
      outlined: false,
      variant: 'secondary',
    },
  ],
  defaultVariants: {
    outlined: false,
    variant: 'primary',
  },
  variants: {
    outlined: {
      false: '',
      true: 'bg-transparent',
    },
    variant: {
      ghost:
        'border-0 text-button-ghost-default-foreground hover:text-button-ghost-hover-foreground focus-visible:px-1',
      primary: '',
      secondary: '',
    },
  },
});

type ButtonProps = React.InputHTMLAttributes<HTMLButtonElement> & {
  asChild?: boolean;
  href?: string;
  icon?: IconProps['icon'];
  iconClassName?: string;
  iconPosition?: 'before' | 'after';
  loading?: boolean;
  newTab?: boolean;
  outlined?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  variant?: 'primary' | 'secondary' | 'ghost';
};

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      href,
      icon,
      iconClassName,
      iconPosition = 'before',
      loading,
      newTab,
      outlined,
      type = 'button',
      variant,
      ...props
    },
    ref,
  ) => {
    const loadingComponent = (
      <Icon className={cn('h-4 w-4 animate-spin', iconClassName)} icon={{ name: 'spinner-third', styling: 'far' }} />
    );
    const iconComponent = !!icon && <Icon className={cn('h-4 w-4', iconClassName)} icon={icon} />;
    const classNameProp = buttonVariants({ className, outlined, variant });

    const content = (
      <>
        {iconPosition === 'before' && <>{loading ? loadingComponent : iconComponent}</>}
        {children}
        {iconPosition === 'after' && <>{loading ? loadingComponent : iconComponent}</>}
      </>
    );

    if (href) {
      return (
        <TextLink
          newTab={newTab}
          className={classNameProp}
          href={href}
          ref={ref as React.ForwardedRef<HTMLAnchorElement>}
        >
          {content}
        </TextLink>
      );
    }

    return (
      <button
        {...props}
        disabled={disabled || loading}
        className={classNameProp}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
        type={type}
      >
        {content}
      </button>
    );
  },
);

const buttonIconVariants = tv({
  base: 'flex size-8 p-0 items-center justify-center rounded-full border border-transparent bg-accent-40 fill-accent-100 hover:border-accent-60',
  defaultVariants: {
    variant: 'default',
  },
  variants: {
    variant: {
      default: 'bg-accent-40 fill-accent-100 hover:bg-accent-60',
      ghost: 'bg-transparent',
    },
  },
});

type ButtonIconProps = ButtonProps & {
  icon: IconProps['icon'];
  variant?: 'default' | 'ghost';
};

const IconButton = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ className, icon, type = 'button', variant, ...props }, ref) => (
    <button
      className={cn(
        buttonIconVariants({ className, variant }),
        'focus-ring disabled:accent-20 disabled:opacity-50 disabled:hover:border-0',
      )}
      ref={ref}
      type={type}
      {...props}
    >
      <Icon icon={icon} />
    </button>
  ),
);

IconButton.displayName = 'IconButton';

export { Button, IconButton };
