import { CRITEO_EVENT_TYPES } from 'constants/criteo';
import { getPageId } from 'helpers/CriteoClient';
import { nextApiClient } from 'helpers/NextApiClient';
import { AlgoliaRefinements, Hit } from 'types/Algolia';
import { Cart } from 'types/Cart';
import { Category } from 'types/Category';
import { CriteoApiResponse, CriteoProduct } from 'types/Criteo';
import { Order } from 'types/Order';
import { CriteoLegalInfo, CriteoTracking, Product } from 'types/Product';
import {
  getCategoryBreadcrumb,
  getProductsFromResult,
  getTrackingPlacementsFromResult,
  mapAlgoliaRefinementsToCriteoFilters,
  mapSigningsToCriteoFilters,
  mapSolrQueryToCriteoFilters,
  normalizeCartEntries,
  pipeConcat,
  reduceNormalizedCartEntry,
} from 'utils/criteoUtil';
import { log } from 'utils/loggerUtil';
import { isMobile } from 'utils/screenUtils';
import { CRITEO_API_ENABLED, CriteoEventType } from '../../constants/criteo';
import { logErrorResponse } from '../../utils/loggerUtil';
import { fetchProductsByQuery } from '../product/connector';

const fetchSponsoredProducts = async (eventType: CriteoEventType | null, result?: CriteoApiResponse | null) => {
  try {
    if (eventType && result) {
      const data = result?.data;

      const placementTrackingData = getTrackingPlacementsFromResult(data, eventType) ?? [];
      const placementTracking = Array.isArray(placementTrackingData) ? placementTrackingData : [placementTrackingData];
      const criteoProducts = getProductsFromResult(data, eventType);

      if (!criteoProducts?.length) {
        return { placementTracking, trackedProducts: [] };
      }

      const eachProductIsPlacement = placementTracking?.length === criteoProducts?.length;

      const productCodesQuery = criteoProducts
        .map((criteoProduct) => criteoProduct?.ProductId && `:code:${criteoProduct.ProductId}`)
        .join('');

      const fetchedProducts = await fetchProductsByQuery({
        currentPage: 0,
        pageSize: criteoProducts?.length,
        query: productCodesQuery,
      });

      // Enrich our products with criteo tracking data
      // Start from criteo products and try to match them with fetched products => this way we keep the criteo sort order
      // If we can't match the data we skip the product
      const trackedProducts = criteoProducts.reduce((prev: Product[], criteoProduct: CriteoProduct, index) => {
        const fetchedProduct = fetchedProducts?.products?.find(
          (product) => criteoProduct.ProductId?.toLowerCase() === product.code?.toLowerCase(),
        );

        if (!fetchedProduct) {
          return prev;
        }

        const trackingInfo: CriteoTracking = {
          OnBasketChangeBeacon: criteoProduct.OnBasketChangeBeacon,
          OnClickBeacon: criteoProduct.OnClickBeacon,
          OnLoadBeacon: criteoProduct.OnLoadBeacon,
          OnViewBeacon: criteoProduct.OnViewBeacon,
          OnWishlistBeacon: criteoProduct.OnWishlistBeacon,
          ProductPage: criteoProduct.ProductPage,
          ...(eachProductIsPlacement && { OnFormatViewBeacon: placementTracking[index].OnViewBeacon }),
          ...(eachProductIsPlacement && { OnFormatLoadBeacon: placementTracking[index].OnLoadBeacon }),
        };
        const legalInfo: CriteoLegalInfo = {
          behalf: criteoProduct.behalf,
          dsa_url: criteoProduct.dsa_url,
          paid: criteoProduct.paid,
        };
        const trackedProduct = {
          ...fetchedProduct,
          isSponsored: true,
          legalInfo,
          tracking: trackingInfo,
        };

        return [...prev, trackedProduct];
      }, []);

      return { placementTracking, trackedProducts };
    }
  } catch (error) {
    logErrorResponse('Criteo Connector', error, 'An error occurred while fetching sponsored product data');
    throw error;
  }
};

const pushViewHomeEvent = async () => {
  if (!CRITEO_API_ENABLED) return;

  try {
    const requestData = { isMobile: isMobile() };

    return (await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_HOME}`,
    })) as CriteoApiResponse;
  } catch (error) {
    log('Criteo pushViewHomeEvent', 'Something went wrong pushing viewHome analytics', error);
  }
};

interface PushViewCategoryParams {
  category: Category;
  categoryOverride?: string;
  isListView?: boolean;
  listSize: number;
  pageNumber: number;
  products: Product[];
  signings?: string;
  solrQuery?: string;
}

const pushViewCategoryEvent = async ({
  category,
  categoryOverride,
  isListView = false,
  listSize,
  pageNumber,
  products,
  signings,
  solrQuery,
}: PushViewCategoryParams) => {
  if (!CRITEO_API_ENABLED) return;

  try {
    const item = products?.reduce((prev: string, entry) => (entry.code ? pipeConcat(prev, entry.code) : prev), '');

    const mappedFilters = [mapSolrQueryToCriteoFilters(solrQuery), mapSigningsToCriteoFilters(signings)]
      .filter(Boolean)
      .join(',');

    const requestData = {
      category: categoryOverride ?? getCategoryBreadcrumb(category),
      filters: mappedFilters,
      isListView,
      isMobile: isMobile(),
      item,
      listSize,
      pageNumber,
    };

    return (await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_CATEGORY}`,
    })) as CriteoApiResponse;
  } catch (error) {
    log('Criteo pushViewCategoryEvent', 'Something went wrong pushing viewCategory analytics', error);
  }
};

interface PushViewCustomCampaignEventProps {
  campaignId?: string;
  category?: string;
  //For future use
  filters?: string;
}

const pushViewCustomCampaignEvent = async ({
  campaignId,
  category,
  filters: filtersProp,
}: PushViewCustomCampaignEventProps) => {
  if (!CRITEO_API_ENABLED) return;

  const filters = mapSigningsToCriteoFilters(filtersProp);

  try {
    const requestData = {
      campaignId,
      category,
      filters,
      isMobile: isMobile(),
    };

    return (await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN}`,
    })) as CriteoApiResponse;
  } catch (error) {
    log('Criteo pushViewCustomCampaignEvent', 'Something went wrong pushing viewCustomCampaign analytics', error);
  }
};

interface PushViewSearchResultProps {
  hits: Array<Hit | Product>;
  isListView?: boolean;
  keywords: string;
  listSize: number;
  pageNumber: number;
  query?: ViewSearchResultQuery;
}

type ViewSearchResultQuery = AlgoliaRefinements | string;

const pushViewSearchResult = async ({
  hits,
  isListView = false,
  keywords,
  listSize,
  pageNumber,
  query,
}: PushViewSearchResultProps) => {
  if (!CRITEO_API_ENABLED) return null;

  try {
    const item = hits?.reduce((prev: string, entry) => (entry.code ? pipeConcat(prev, entry.code) : prev), '');

    const filters =
      typeof query === 'string' ? mapSolrQueryToCriteoFilters(query) : mapAlgoliaRefinementsToCriteoFilters(query);

    const requestData = {
      filters,
      isListView,
      isMobile: isMobile(),
      item,
      keywords,
      listSize,
      pageNumber,
    };

    return (await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_SEARCH_RESULT}`,
    })) as CriteoApiResponse; // TODO: Review how we can extend the nextApiClient to accept generics
  } catch (error) {
    log('Criteo pushViewSearchResult', 'Something went wrong pushing viewSearchResult analytics', error);
  }
};

interface PushViewItemEventProps {
  availability: boolean;
  filters?: string;
  item: string;
  listPrice: number;
  price: number;
}

const pushViewItemEvent = async ({ availability, filters, item, listPrice, price }: PushViewItemEventProps) => {
  if (!CRITEO_API_ENABLED) return;

  try {
    const requestData = {
      availability: availability ? 1 : 0,
      filters,
      isMobile: isMobile(),
      item,
      listPrice,
      price,
    };

    return (await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_ITEM}`,
    })) as CriteoApiResponse;
  } catch (error) {
    log('Criteo pushViewItemEvent', 'Something went wrong pushing ViewItem analytics', error);
  }
};

const pushViewBasketEvent = async (cart: Cart) => {
  if (!CRITEO_API_ENABLED) return;

  const normalizedCartEntries = cart.entries ? normalizeCartEntries(cart.entries) : [];
  const { item, price, quantity } = normalizedCartEntries.reduce(reduceNormalizedCartEntry, {
    item: '',
    price: '',
    quantity: '',
  });

  try {
    const requestData = {
      isMobile: isMobile(),
      item,
      price,
      quantity,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.VIEW_BASKET}`,
    });
  } catch (error) {
    log('Criteo pushViewBasketEvent', 'Something went wrong pushing viewBasket analytics', error);
  }
};

interface PushAddToCartEventProps {
  currentPageEvent: CriteoEventType;
  item: string;
  price: number;
  quantity: number;
}

const pushAddToCartEvent = async ({ currentPageEvent, item, price, quantity }: PushAddToCartEventProps) => {
  if (!CRITEO_API_ENABLED) return;
  try {
    const pageId = getPageId(currentPageEvent, isMobile());
    const pageUid = document?.body.getAttribute('data-criteo-page-uid');

    const requestData = {
      isMobile: isMobile(),
      item,
      pageId,
      pageUid,
      price,
      quantity,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.ADD_TO_CART}`,
    });
  } catch (error) {
    log('Criteo pushAddToCartEvent', 'Something went wrong pushing addToCart analytics', error);
  }
};

const pushTrackTransactionEvent = async (order: Order) => {
  if (!CRITEO_API_ENABLED) return;

  const normalizedOrderEntries = order?.entries ? normalizeCartEntries(order.entries) : [];
  const { item, price, quantity } = normalizedOrderEntries.reduce(reduceNormalizedCartEntry, {
    item: '',
    price: '',
    quantity: '',
  });

  const { guid: transactionId } = order;

  try {
    const requestData = {
      isMobile: isMobile(),
      item,
      price,
      quantity,
      transactionId,
    };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/${CRITEO_EVENT_TYPES.TRACK_TRANSACTION}`,
    });
  } catch (error) {
    log('Criteo pushTrackTransactionEvent', 'Something went wrong pushing addToCart analytics', error);
  }
};

const pushCriteoBeacon = async (beacon: string) => {
  if (!CRITEO_API_ENABLED) return;
  try {
    if (!beacon) throw new Error('No beacon passed to push');

    const beaconUrl = `${beacon.startsWith('//') ? 'https:' : ''}${beacon}`;
    const requestData = { beacon: beaconUrl };

    return await nextApiClient({
      data: requestData,
      method: 'POST',
      url: `/criteo/beacon`,
    });
  } catch (error) {
    log('Criteo pushCriteoBeacon', 'Something went wrong pushing beacon analytics', error);
  }
};

export {
  fetchSponsoredProducts,
  pushAddToCartEvent,
  pushCriteoBeacon,
  pushTrackTransactionEvent,
  pushViewBasketEvent,
  pushViewCategoryEvent,
  pushViewCustomCampaignEvent,
  pushViewHomeEvent,
  pushViewItemEvent,
  pushViewSearchResult,
};
