import { keepPreviousData, skipToken, useQuery } from '@tanstack/react-query';
import config from 'config';
import { useRouterParam } from 'hooks/useRouterParam';
import { MakeOptional } from 'utils/typeUtil';
import { storesKeys } from '.';
import {
  FetchPickupLocationsByProductCodeParams,
  FetchStoresByLocationParams,
  FetchStoresByProductCodeParams,
  FetchStoresParams,
  fetchCustomerServiceDetails,
  fetchPickupLocationsByProductCode,
  fetchStore,
  fetchStores,
  fetchStoresByLocation,
  fetchStoresByProductCode,
} from './connector';

const useStores = ({ latitude, longitude, open, openSunday, signings }: FetchStoresParams) =>
  useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchStores({ latitude, longitude, open, openSunday, signings }),
    queryKey: storesKeys.stores({ latitude, longitude, open, openSunday, signings }),
  });

const useStoreDetail = (id?: string) => {
  const storeIdParam = useRouterParam('id');
  const storeId = id ?? storeIdParam;
  return useQuery({
    enabled: !!storeId,
    placeholderData: keepPreviousData,
    queryFn: () => fetchStore(storeId),
    queryKey: storesKeys.store(storeId),
  });
};

const useCustomerServiceDetails = () =>
  useQuery({
    placeholderData: keepPreviousData,
    queryFn: () => fetchCustomerServiceDetails(),
    queryKey: storesKeys.customerServiceDetails(),
  });

type UseStoresByLocationParams = MakeOptional<
  FetchStoresByLocationParams,
  'countryCode' | 'postalCode' | 'location'
> & {
  enabled?: boolean;
};

const useStoresByLocation = ({
  countryCode = config.defaultCountry,
  enabled = true,
  fields = 'FULL',
  location,
  pageSize = 5,
  postalCode,
}: UseStoresByLocationParams) =>
  useQuery({
    enabled: enabled && !!countryCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn:
      !!countryCode && !!location && !!postalCode
        ? () => fetchStoresByLocation({ countryCode, fields, location, pageSize, postalCode })
        : skipToken,
    queryKey: storesKeys.storesByLocation({ countryCode, fields, location, pageSize, postalCode }),
    select: (data) => data?.stores || [],
  });

const useStoresByProductCode = ({
  countryCode = config.defaultCountry,
  enabled = true,
  location,
  postalCode,
  productCode,
}: FetchStoresByProductCodeParams & { enabled?: boolean }) =>
  useQuery({
    enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchStoresByProductCode({ countryCode, location, postalCode, productCode }),
    queryKey: storesKeys.storesByProductCode({ countryCode, location, postalCode, productCode }),
    select: (data) => data?.stores || [],
  });

type UsePickupLocationsByProductCodeParams = MakeOptional<
  FetchPickupLocationsByProductCodeParams,
  'location' | 'postalCode' | 'productCode'
> & {
  enabled?: boolean;
};

const usePickupLocationsByProductCode = ({
  countryCode = config.defaultCountry,
  enabled = true,
  location,
  postalCode,
  productCode,
}: UsePickupLocationsByProductCodeParams) =>
  useQuery({
    enabled: enabled && !!productCode && !!location && !!postalCode,
    placeholderData: keepPreviousData,
    queryFn:
      !!productCode && !!location && !!postalCode
        ? () => fetchPickupLocationsByProductCode({ countryCode, location, postalCode, productCode })
        : skipToken,
    queryKey: storesKeys.pickupLocationsByProductCode({ countryCode, location, postalCode, productCode }),
    select: (data) => data?.pointOfServices || [],
  });

export {
  useCustomerServiceDetails,
  usePickupLocationsByProductCode,
  useStoreDetail,
  useStores,
  useStoresByLocation,
  useStoresByProductCode,
};
