import { MakeOptional } from 'utils/typeUtil';
import {
  FetchPickupLocationsByProductCodeParams,
  FetchStoresByLocationParams,
  FetchStoresByProductCodeParams,
  FetchStoresParams,
} from './connector';

export const storesKeys = {
  customerServiceDetails: () => ['customerServiceDetails'],
  pickupLocationsByProductCode: (
    params: MakeOptional<FetchPickupLocationsByProductCodeParams, 'location' | 'postalCode' | 'productCode'>,
  ) => ['stockLocationsByProductCode', params],
  store: (storeId?: string) => ['store', storeId],
  stores: (params: FetchStoresParams = {}) => ['stores', params],
  storesByLocation: (params: MakeOptional<FetchStoresByLocationParams, 'countryCode' | 'postalCode' | 'location'>) => [
    'storesByLocation',
    params,
  ],
  storesByProductCode: (params: FetchStoresByProductCodeParams) => ['storesByProductCode', params],
};
