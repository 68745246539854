import { apiClient } from 'helpers/ApiClient';
import { CountriesResult, PostalCodeLocations } from 'types/Address';
import { logErrorResponse } from 'utils/loggerUtil';

const fetchCountries = async () => {
  try {
    const { data } = await apiClient<CountriesResult>({
      url: `/postalCode/countries`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Postalcode Connector', error, 'Error fetching countries');
    throw error;
  }
};

export type FetchPostalCodeLocationsParams = {
  borderZoneOnly?: boolean;
  countryIsoCode?: string;
  searchTerm?: string;
};

const fetchPostalCodeLocations = async ({
  borderZoneOnly = false,
  countryIsoCode,
  searchTerm,
}: FetchPostalCodeLocationsParams) => {
  try {
    const { data } = await apiClient<PostalCodeLocations>({
      params: {
        borderZoneOnly,
        country: countryIsoCode,
        fields: 'FULL',
        input: searchTerm,
      },
      url: `/postalCode/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Postalcode Connector', error, 'Error fetching postal code locations');
    throw error;
  }
};

export { fetchCountries, fetchPostalCodeLocations };
