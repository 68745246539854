import useRouter from './useRouter';

const usePrice = () => {
  const { locale } = useRouter();

  const formatPrice = (price: number) =>
    new Intl.NumberFormat(locale, {
      currency: 'EUR',
      style: 'currency',
    }).format(price);

  return {
    formatPrice,
  };
};

export default usePrice;
