import axios from 'axios';
import { useUserStore } from 'store/user';

export const nextApiClient = axios.create({
  baseURL: '/api',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 30000,
  withCredentials: true,
});

nextApiClient.interceptors.request.use((config) => {
  const customerId = useUserStore?.getState()?.user?.customerId;
  if (customerId) {
    // eslint-disable-next-line no-param-reassign
    config.data = { ...config.data, customerId };
  }
  return config;
});

nextApiClient.interceptors.response.use((response) => {
  const criteoPageUid = response.data?.['page-uid'];
  document?.body.setAttribute('data-criteo-page-uid', criteoPageUid);
  return response;
});
