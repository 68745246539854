import { AlgoliaRefinements } from 'types/Algolia';
import { CartEntry } from 'types/Cart';
import { Category } from 'types/Category';
import { CriteoApiResponse, CriteoDataSearch, CriteoDataViewItem } from 'types/Criteo';
import { Product } from 'types/Product';
import { CRITEO_EVENT_TYPES, CriteoEventType, SPONSORED_PRODUCTS_FIXED_POSITIONS } from '../constants/criteo';
import { pathnames } from '../i18n/pathnames';

export const pipeConcat = (previous: string, current: string | number): string =>
  `${previous ? `${previous}|` : ''}${current}`;

export const reduceNormalizedCartEntry = (
  prev: { item: string; price: string; quantity: string },
  entry: CartEntry,
) => ({
  item: entry.product?.code ? pipeConcat(prev.item, entry.product.code) : prev.item,
  price: entry.product?.price?.value ? pipeConcat(prev.price, entry.product.price.value) : prev.price,
  quantity: entry.quantity ? pipeConcat(prev.quantity, entry.quantity) : prev.quantity,
});

export const getCategoryBreadcrumb = (category: Category): string | undefined => {
  if (!category.superCategory) {
    return category.code;
  }
  return `${getCategoryBreadcrumb(category.superCategory)}>${category.code}`;
};

// Get unique cart entries with correct quantities
export const normalizeCartEntries = (cartEntries: CartEntry[]) => {
  const normalizedEntries: Record<string, CartEntry> = {};

  cartEntries?.forEach((entry) => {
    if (!entry.product?.code) return;

    const savedEntry = normalizedEntries[entry.product.code];

    if (savedEntry) {
      savedEntry.quantity = (savedEntry.quantity ?? 0) + 1;
      return;
    }

    normalizedEntries[entry.product.code] = { ...entry };
  });

  return Object.values(normalizedEntries);
};

export const mapSolrQueryToCriteoFilters = (solrQuery?: string) => {
  if (!solrQuery) {
    return '';
  }

  const queryParts = solrQuery.toLowerCase().split(':');
  const queryParams = new Map<string, string[]>();

  // If empty key in front => key=sort
  if (queryParts[0] === '') {
    queryParts[0] = 'sort';
  }
  // Traverse parts in key,value pairs
  for (let i = 0; i < queryParts.length; i += 2) {
    const key = queryParts[i]?.toLowerCase();
    const existingValue = queryParams.get(key) || [];
    queryParams.set(key, [...existingValue, queryParts[i + 1]]);
  }

  const criteoFilters = [];

  const brandFilter = queryParams.get('brand') || [];
  if (brandFilter.length) {
    criteoFilters.push(`(brand,in,${brandFilter?.map((brand) => brand.toLowerCase()).join(',')})`);
  }

  const priceValueFilter = queryParams.get('pricevalue')?.[0];
  if (priceValueFilter) {
    const priceValueRegex = /(\d+)\b/g;
    const [priceValueMin, priceValueMax] = priceValueFilter.match(priceValueRegex) || [];
    criteoFilters.push(`(price,ge,${priceValueMin}),(price,le,${priceValueMax})`);
  }

  const priceRangeFilter = queryParams.get('genericpricerange');
  if (!priceValueFilter?.length && priceRangeFilter?.length) {
    const priceRangeRegex = /(\d+)\b/g;
    const { max: priceValueMax, min: priceValueMin } = priceRangeFilter.reduce(
      (prev: { max: number | null; min: number | null }, current) => {
        const [min, max] = current.match(priceRangeRegex) ?? [];
        const newMin = prev.min === null ? Number(min) : Math.min(prev.min, Number(min));
        const newMax = prev.max ? Math.max(prev.max, Number(max)) : Number(max);
        return { max: newMax, min: newMin };
      },
      { max: null, min: null },
    );

    if (priceValueMin) {
      criteoFilters.push(`(price,ge,${priceValueMin})`);
    }
    if (priceValueMax) {
      criteoFilters.push(`(price,le,${priceValueMax})`);
    }
  }

  return criteoFilters.join(',');
};

export const mapAlgoliaRefinementsToCriteoFilters = (refinements?: AlgoliaRefinements) => {
  if (!refinements?.length) {
    return '';
  }

  const criteoFilters = [];

  const brandName = refinements
    .reduce((prev: string[], refinement) => {
      if (refinement.attributeName === 'brandName' && refinement.name) {
        return prev.concat(refinement.name.toLowerCase());
      }
      return prev;
    }, [])
    .join(',');

  if (brandName) {
    criteoFilters.push(`(brand,in,${brandName})`);
  }

  const priceValueMin = refinements.find(
    (refinement) => refinement.attributeName === 'priceValue' && refinement.operator === '>=',
  );

  if (priceValueMin) {
    criteoFilters.push(`(price,ge,${priceValueMin.numericValue})`);
  }

  const priceValueMax = refinements.find(
    (refinement) => refinement.attributeName === 'priceValue' && refinement.operator === '<=',
  );

  if (priceValueMax) {
    criteoFilters.push(`(price,le,${priceValueMax.numericValue})`);
  }

  return criteoFilters.join(',');
};

export const mapSigningsToCriteoFilters = (signings: string | undefined) => {
  if (!signings) {
    return '';
  }

  return `(signing,in,${signings})`;
};

export const mapProductToCriteoFilters = (product: Product) => {
  if (product.sponsoredProductsMode === 'SAME_BRAND' && product.brand?.name) {
    const brandName = product.brand.name.toLowerCase();
    return `(brand,eq,${brandName})`;
  }
  return '';
};

export const mapPathnameToCriteoEvent = (currentPathname: string) => {
  switch (currentPathname) {
    case pathnames.SEARCH:
      return CRITEO_EVENT_TYPES.VIEW_SEARCH_RESULT;
    case pathnames.CATEGORY:
      return CRITEO_EVENT_TYPES.VIEW_CATEGORY;
    case pathnames.PRODUCT:
      return CRITEO_EVENT_TYPES.VIEW_ITEM;
    case pathnames.INDEX:
      return CRITEO_EVENT_TYPES.VIEW_HOME;
    default:
      return null;
  }
};

export const getViewItemBodyData = (product: Product) => ({
  availability: +!!product?.stock?.stockLevel,
  item: product?.sku || product?.code,
  listPrice: product?.price?.value,
  price: product?.strikePrice?.value || product?.price?.value,
});

export const getProductsFromResult = (result: CriteoApiResponse['data'], event: CriteoEventType) => {
  switch (event) {
    case CRITEO_EVENT_TYPES.VIEW_CATEGORY:
    case CRITEO_EVENT_TYPES.VIEW_SEARCH_RESULT:
      return (result as CriteoDataSearch)?.placements?.map(
        (placement) => Object.values(placement)?.[0]?.[0]?.products?.[0],
      );
    case CRITEO_EVENT_TYPES.VIEW_HOME:
    case CRITEO_EVENT_TYPES.VIEW_ITEM:
    case CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN:
      return (result as CriteoDataViewItem)?.products ?? [];
    default:
      return [];
  }
};

// TODO: Create custom result object with the event as one of the properties so a typeguard can be implemented
export const getTrackingPlacementsFromResult = (result: CriteoApiResponse['data'], event: CriteoEventType) => {
  switch (event) {
    case CRITEO_EVENT_TYPES.VIEW_CATEGORY:
    case CRITEO_EVENT_TYPES.VIEW_SEARCH_RESULT:
      return (result as CriteoDataSearch)?.placements?.map((placement) => {
        const placementInfo = Object.values(placement)?.[0]?.[0];
        return { OnLoadBeacon: placementInfo.OnLoadBeacon, OnViewBeacon: placementInfo.OnViewBeacon };
      });
    case CRITEO_EVENT_TYPES.VIEW_HOME:
    case CRITEO_EVENT_TYPES.VIEW_ITEM:
    case CRITEO_EVENT_TYPES.VIEW_CUSTOM_CAMPAIGN:
      return [
        {
          OnLoadBeacon: (result as CriteoDataViewItem)?.OnLoadBeacon,
          OnViewBeacon: (result as CriteoDataViewItem)?.OnViewBeacon,
        },
      ];
    default:
      return null;
  }
};

export const getSearchResultsWithSponsoredProducts = (products: Product[], sponsoredProducts: Product[]) => {
  if (!products?.length) {
    return [];
  }
  if (!sponsoredProducts?.length) {
    return products;
  }
  // Remove duplicates
  const productsToShow = products.reduce((prev: Product[], product) => {
    const found = sponsoredProducts?.find((sponsoredProduct) => sponsoredProduct?.code === product?.code);

    if (!found) {
      return [...prev, product];
    }

    return prev;
  }, []);

  SPONSORED_PRODUCTS_FIXED_POSITIONS.map((position, index) => {
    const product = sponsoredProducts?.[index];

    if (product) {
      productsToShow.splice(position - 1, 0, product);
    }
  });

  return productsToShow;
};
